import React from 'react';
import FinalizadoraContent from './FinalizadoraContent';
import { FinalizadoraContextProvider } from './FinalizadoraContext';

const Finalizadora: React.FC = () => {
  return (
    <FinalizadoraContextProvider>
      <FinalizadoraContent />
    </FinalizadoraContextProvider>
  );
};

export default Finalizadora;
