export const tipoFinalizadoras = [
  {
    value: 0,
    label: 'Dinheiro',
  },
  {
    value: 1,
    label: 'Cartão/PIX',
  },
  {
    value: 2,
    label: 'Cheque',
  },
  {
    value: 3,
    label: 'Convênio',
  },
  {
    value: 4,
    label: 'Vale',
  },
  {
    value: 5,
    label: 'Ticket',
  },
  {
    value: 6,
    label: 'Outros',
  },
  {
    value: 7,
    label: 'Contravale',
  },
  {
    value: 8,
    label: 'Boleto',
  },
];

export const tipoOperacao = [
  {
    value: -1,
    label: 'Nenhum',
  },
  {
    value: 0,
    label: 'Crédito',
  },
  {
    value: 1,
    label: 'Débito',
  },
  {
    value: 2,
    label: 'PIX',
  },
];
