import styled from 'styled-components';

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.2s;
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      border-bottom: 2px solid #cf1919;
      transition: 0.5s;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.5s;
    }
  }
`;
